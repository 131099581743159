import { createStore } from 'vuex'

export default createStore({
  state: {
    params: {},
    contactInfo: {},
    officeName: "",
    startDate: null,
    spentTime: null,
    errorFields: [],
    managerData: {},
    meetingType: null,
    timerId: null
  },
  getters: {
    params(state) {
      return { ...state.params, meeting_type: state.meetingType };
    },
    contactInfo(state) {
      return state.contactInfo;
    },
    officeName(state) {
      return state.officeName;
    },
    startDate(state) {
      return state.startDate;
    },
    spentTime(state) {
      return state.spentTime;
    },
    errorFields(state) {
      return state.errorFields;
    },
    managerData(state) {
      return state.managerData;
    },
    meetingType(state) {
      return state.meetingType;
    },
  },
  mutations: {
    setFieldValueInState(state, param) {
      //     "phone": "77089739470",
      //     "lang_id": 1,
      //     "project_id": 1,
      //     "setting_direction": "primary",
      //     "location_code": '1'
      Object.assign(state.params, param);
    },
    clearFieldValueInState(state) {
      Object.keys(state.params)?.forEach((key) => delete state.params[key]);
    },
    setContactInfo(state,param) {
        // 'contact_info': 'Даниил Бузинов',
        // 'phone': '77089739470',
        // 'lang_id' : 1
        // ...
      // Object.assign(state.contactInfo, param);
      state.contactInfo = JSON.parse(JSON.stringify(param));
    },
    clearContactInfo(state) {
      Object.keys(state.contactInfo)?.forEach(
        (key) => delete state.contactInfo[key]
      );
    },
    setOfficeName(state, param) {
      state.officeName = param;
    },
    setStartDate(state, date) {
      state.startDate = date;
    },
    setSpentTime(state, ms) {
      state.spentTime = ms;
    },
    setErrorFields(state, field) {
      state.errorFields.push(field);
    },
    deleteFromErrorFields(state, field) {
      state.errorFields = state.errorFields.filter((key) => key !== field);
    },
    setManagerData(state, data) {
      Object.assign(state.managerData, data);
      console.log("state in manager data ", state);
    },
    clearManagerData(state) {
      Object.keys(state.managerData)?.forEach(
        (key) => delete state.managerData[key]
      );
    },
    setMeetingType(state, type) {
      clearTimeout(state.timerId);
      state.timerId = null;

      state.meetingType = type;

      state.timerId = setTimeout(() => {
        state.meetingType = null;
      }, 60 * 3 * 1000);
    },
  },

  actions: {},
  modules: {},
});
